/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/purple-green.css";
@import "styles/global-var.scss";

html {
    overflow-y: overlay;
}

mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $main-color !important;
}

* {
    font-family: $roboto-font !important;
    color: $black-color !important;
}

h1 {
    font-size: 55px !important;
    margin-bottom: 0px !important;
}

h2 {
    font-size: 35px !important;
    margin-bottom: 0px !important;
}

h3 {
    font-size: 25px !important;
    margin-bottom: 0px !important;
}

body {
    margin: 0;
    background: linear-gradient(130deg, rgba(255, 255, 255, 1) 0%, rgba(0, 113, 123, 0.05) 50%, rgba(0, 113, 123, 0.05) 100%);
}

.bounds {
    .inner-bounds {
        padding: 3rem 0rem;
        width: 100%;
    }
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border: 1px solid rgba(0, 113, 123, 0.3);
    border-radius: 10px;

    max-width: 1440px;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
}

.main-color {
    color: $main-color !important;
}
.black-color {
    color: $black-color !important;
}
.strong {
    font-weight: bold !important;
}
.italic {
    font-style: italic !important;
}

.header {
    font-weight: normal !important;
    margin-top: 0rem !important;
    line-height: 1em !important;
    display: flex;
    align-items: center;

    span:first-child {
        color: $black-color !important;
        letter-spacing: 0 !important;
        margin-right: 1rem;
    }
    span:last-child {
        color: $main-color !important;
        letter-spacing: 0 !important;
    }
}
.avatar {
    background-image: url("/assets/images/IMG_9156-removebg-preview.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.content {
    padding: 0rem 5rem;
}

.main-content {
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    max-height: 70%;
    flex-direction: row;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    //
    min-height: 470px;
}

/// ICON ==========================

.facebook {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -100px;
    background-position-y: 0px;
}

.facebook:hover {
    background-position-x: -150px;
    background-position-y: 0px;
}

.twitter {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -50px;
    background-position-y: -100px;
}
.twitter:hover {
    background-position-x: -100px;
    background-position-y: -100px;
}

.linkedin {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -400px;
    background-position-y: 0px;
}
.linkedin:hover {
    background-position-x: -400px;
    background-position-y: -50px;
}
.github {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -300px;
    background-position-y: 0px;
}
.github:hover {
    background-position-x: -350px;
    background-position-y: 0px;
}
.cclaim {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -200px;
    background-position-y: 0px;
}
.cclaim:hover {
    background-position-x: -250px;
    background-position-y: 0px;
}
.gmail {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: 0x;
    background-position-y: 0px;
}
.gmail:hover {
    background-position-x: -50px;
    background-position-y: 0px;
}
.download {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -150px;
    background-position-y: -50px;
}
.download:hover {
    background-position-x: -150px;
    background-position-y: -100px;
}

.note {
    background-image: $bg-image;
    background-size: $bg-size;
    background-position-x: -50px;
    background-position-y: -50px;
}
button.m-icon {
    border: 0px;
    background-color: unset;
}

.arrow {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -400px;
    background-position-y: 0px;
}

.arrow:hover {
    background-position-x: -400px;
    background-position-y: -50px;
}

.icn-cancel {
    background-image: $bg-image;
    background-size: 300px 120px;
    background-position-x: 0px;
    background-position-y: -212px;
    // transform: scale(0.8);
}

.icn-clap {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -200px;
    background-position-y: 0px;
}

.icn-clap:hover {
    background-position-x: -250px;
    background-position-y: 0px;
}

.icn-comment {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -0px;
    background-position-y: 0px;
}

.icn-comment:hover {
    background-position-x: -50px;
    background-position-y: -0px;
}

.icn-source {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -100px;
    background-position-y: 0px;
}

.icn-source:hover {
    background-position-x: -150px;
    background-position-y: 0px;
}

.icn-subcribe {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -300px;
    background-position-y: 0px;
}
.icn-subcribe:hover {
    background-position-x: -350px;
    background-position-y: 0px;
}
.icn-unsubcribe {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -300px;
    background-position-y: -50px;
}
.icn-unsubcribe:hover {
    background-position-x: -350px;
    background-position-y: -50px;
}

.icn-facebook-share {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: 0px;
    background-position-y: -50px;
}
*:hover > .icn-facebook-share,
.icn-facebook-share:hover {
    background-position-x: -50px;
    background-position-y: -50px;
}

.icn-linkedin-share {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -100px;
    background-position-y: -50px;
}
*:hover > .icn-linkedin-share,
.icn-linkedin-share:hover {
    background-position-x: -150px;
    background-position-y: -50px;
}
.icn-twitter-share {
    background-image: $bg-image2;
    background-size: $bg-size2;
    background-position-x: -200px;
    background-position-y: -50px;
}
*:hover > .icn-twitter-share,
.icn-twitter-share:hover {
    background-position-x: -250px;
    background-position-y: -50px;
}

.title-tooltip {
    font-size: 1rem;
    line-height: 1;
    color: white !important;
}

.normal-tooltip {
    font-size: 14px;
    line-height: 1.2;
    min-width: 450px;
    color: white !important;
}
.flex-tooltip {
    font-size: 14px;
    line-height: 1.2;
    color: white !important;
}
::ng-deep .snack-bar-container {
    background: #dcdcdc !important;
    .button:hover .mat-button-wrapper {
        color: $main-color2;
    }
}
.pointer {
    cursor: pointer !important;
}

// Custom scrollbar
/* width */
::-webkit-scrollbar {
    width: 6px;
    overscroll-behavior: none;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.menu-right:hover {
    opacity: 1;
    transition: opacity 1s;
    .shake:hover {
        animation: shake 0.3s;
    }
    button.shake:active {
        transform: scale(0.96);
    }
}

.menu-right {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    opacity: 0.6;
    transition: opacity 1s;

    label {
        font-weight: 500;
    }

    button {
        display: inline-block;
        width: 50px;
        height: 50px;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@media screen and (max-width: 1279px) {
    body {
        margin: 0px;
    }
    #mainTab.mat-tab-group > mat-tab-header {
        background: white;
    }
    .bounds {
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 0px;
        border: 0px;
        box-shadow: none;
        .content {
            padding: 0rem 0rem;
            .header {
                justify-content: center;
            }

            .main-content {
                flex-direction: column !important;
                align-items: center;
            }
        }
        .inner-bounds {
            background: #f3f8f9;
        }
    }
}
@media screen and (max-width: 539px) {
    // Content
    .bounds {
        .content {
            padding: 0rem 0rem;
            .header {
                flex-direction: column !important;
                span:first-child {
                    margin-right: 0px;
                }
            }
            .main-content {
                max-width: 95%;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        .header {
            font-size: 3rem !important;
        }
    }
}

// @media screen and (max-width: 400px)  {
//     #mainTab {
//         height: 35px;
//         div {
//             height: 35px !important;
//         }
//     }
// }

@import "styles/prism-coy-without-shadows.scss";
