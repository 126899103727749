$roboto-font: "Roboto";
$roboto-font-serif: "Roboto Serif script=latin rev=1";

$main-color: rgba(0, 113, 123);
$main-color2: #f29e03;
$black-color: rgba(51, 51, 51);
$bg_topic_img: #daf2e5;
$bg_overlay: rgba(255, 255, 255, 0.7);

$bg-size: 450px 150px;
$bg-image: url("/assets/images/icon_sets.svg");

$bg-size2: 450px 100px;
$bg-image2: url("/assets/images/icon_sets2.svg");
