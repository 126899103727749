/**
 * Coy without shadows
 * Based on Tim Shedor's Coy theme for prism.js
 * Author: RunDevelopment
 */
pre *, code *{
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace !important;
}
#blog-post {
    code[class*="language-"],
    pre[class*="language-"] {
        color: black !important;
        background: none !important;
        font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace !important;
        font-size: 1em !important;
        text-align: left !important;
        white-space: pre !important;
        word-spacing: normal !important;
        word-break: normal !important;
        word-wrap: normal !important;
        line-height: 1.5 !important;

        -moz-tab-size: 4 !important;
        -o-tab-size: 4 !important;
        tab-size: 4 !important;

        -webkit-hyphens: none !important;
        -moz-hyphens: none !important;
        -ms-hyphens: none !important;
        hyphens: none !important;
    }

	.code-toolbar{
		position: relative;
		.toolbar{
			position: absolute;
			top: 0px;
			right: 0;
			button{
				border-radius: 0px;
				border-width: 0px;
				height: 21px;
			}
		}
	}

    /* Code blocks */
    pre[class*="language-"] {
        position: relative !important;
        border-left: 10px solid #e7f1f9 !important;
        box-shadow: -1px 0 0 0 #f29e03, 0 0 0 1px #f29e03 !important;
        background-color: #fdfdfd !important;
        background-image: linear-gradient(transparent 50%, rgba(69, 142, 209, 0.04) 50%) !important;
        background-size: 3em 3em !important;
        background-origin: content-box !important;
        background-attachment: local !important;
        margin: 0.5em 0 !important;
        padding: 0 1em !important;
    }

    pre[class*="language-"] > code {
        display: block !important;
    }

    /* Inline code */
    :not(pre) > code[class*="language-"] {
        position: relative !important;
        padding: 0.2em !important;
        border-radius: 0.3em !important;
        color: #c92c2c !important;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        display: inline !important;
        white-space: normal !important;
        background-color: #fdfdfd !important;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
    }

    .token.comment,
    .token.block-comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
        color: #7d8b99 !important;
    }

    .token.punctuation {
        color: #5f6364 !important;
    }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.function-name,
    .token.constant,
    .token.symbol,
    .token.deleted {
        color: #c92c2c !important;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.function,
    .token.builtin,
    .token.inserted {
        color: #2f9c0a !important;
    }

    .token.operator,
    .token.entity,
    .token.url,
    .token.variable {
        color: #a67f59 !important;
        background: rgba(255, 255, 255, 0.5) !important;
    }

	.token.keyword{
        color: #c92c2c !important;
		font-weight: 600;
	}

    .token.atrule,
    .token.attr-value,
    .token.class-name {
        color: #1990b8 !important;
    }

    .token.regex,
    .token.important {
        color: #e90 !important;
    }

    .language-css .token.string,
    .style .token.string {
        color: #a67f59 !important;
        background: rgba(255, 255, 255, 0.5) !important;
    }

    .token.important {
        font-weight: normal !important;
    }

    .token.bold {
        font-weight: bold !important;
    }

    .token.italic {
        font-style: italic !important;
    }

    .token.entity {
        cursor: help !important;
    }

    .token.namespace {
        opacity: 0.7 !important;
    }
}
